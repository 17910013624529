import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import BasicList from "../components/BasicList"
import Container from "../components/Container"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import SubTitle from "../components/SubTitle"
import Text from "../components/Text"
import Title from "../components/Title"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Käyttöliittymäsuunnittelu"
        description="Teemme käyttöliittymäsuunnittelua web- ja mobiilialustoille. Me varmistamme parhaan lopputuloksen, sekä parhaan halutun käyttökokemuksen!"
        pageUrl={links.uiDesign}
        pageImage={metaImg}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <h1>
              <span className="block">Käyttöliittymäsuunnittelu</span>
            </h1>
            <span className="block text-indigo-400">palvelut</span>
          </HeroTitle>
          <HeroLead>
            Käyttöliittymäsuunnittelu (UI) & käyttäjäkokemussuunnittelu (UX
            Design).
          </HeroLead>
          <HeroLead>
            Hyvä koodi ei pelasta palvelua, jonka käyttäjäkokemus ei tyydytä sen
            käyttäjää.
          </HeroLead>
        </Hero>
      </section>

      <section id="ui-ux">
        <DotSection dotPosition="top-right">
          <div className="grid lg:grid-cols-2 md:gap-x-8">
            <div>
              <Title tag="p">UI & UX Design</Title>
              <Text>
                Käyttöliittymäsuunnittelu (UI) ja käyttäjäkokemussuunnittelu (UX
                Design) on iso osa tietokoneiden, mobiililaitteiden,
                ohjelmistojen, nettisivujen, laitteiden ja koneiden suunnittelua
                ja toteutusta. Käyttöliittymäsuunnittelulla ja
                käyttäjäkokemuksella pyritään luomaan yksinkertainen ja selkeä
                käyttöympäristö tuotteen käyttäjälle, eli pyrimme luomaan
                käyttöliittymän, jossa tuotamme käyttäjälle halutunlaisen
                kokemuksen.
              </Text>
              <Text>
                Erinomainen käyttäjäkokemus ei synny itsestään, vaan se vaatii
                ajatustyötä ja paljon testaamista.
              </Text>

              <Text>
                Käyttöliittymäsuunnittelu on kehittynyt viime vuosina todella
                paljon ja käyttäjien vaatima taso käyttöliittymältä on kasvanut
                ja asettanut rajat korkealle. Käyttöliittymä- ja
                käyttäjäkokemussuunnittelun digitaalinen toteutus on osa
                projektin suunnitteluvaihetta.
              </Text>
              <Text>
                Teemme käyttöliittymäsuunnittelua web- ja mobiilialustoille. Me
                varmistamme parhaan lopputuloksen sekä parhaan halutun
                käyttökokemuksen, joten ota rohkeasti yhteyttä ja kysy lisää!
              </Text>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/2-left-trans-color.svg"
                alt="Applikaatio layoutit"
              />
            </div>
          </div>
        </DotSection>
      </section>

      <section id="ui" className="bg-gray-800">
        <Container>
          <Title tag="h2" textColor="text-white">
            Käyttöliittymäsuunnittelu (UI)
          </Title>
          <Text textColor="text-white">
            User Interface Design:illa (UI), tarkoitetaan sitä, miten valmiin
            tuotteen tai palvelun käyttäminen tehdään mahdollisimman helpoksi,
            tehokkaaksi ja joustavaksi sen eri käyttäjäryhmille.
            Käyttöliittymäsuunnittelu on eri asia kuin käyttökokemus ja niitä ei
            tule sekoittaa keskenään.
          </Text>
          <Text textColor="text-white">
            Käyttöliittymäsuunnittelussa korostuu käyttäjien tarpeiden ja heidän
            käyttöympäristöjen ymmärtäminen. Sen lisäksi suunnittelussa
            määritellään käyttäjille oleelliset toiminnot, elementit sekä
            käyttöliittymän rakenne.
          </Text>
          <Text textColor="text-white">
            Käyttöliittymä tulee suunnitella siten, että käyttäjän ei tarvitse
            opetella asioita ulkoa tai nähdä vaivaa käyttääkseen järjestelmää.
          </Text>
          <Text textColor="text-white">
            Onnistuneen käyttöliittymäsuunnittelun lopputuloksena on yrityksen
            brändimielikuvan kehittyminen sekä lisääntynyt asiakastyytyväisyys.
          </Text>
        </Container>
      </section>

      <section id="ux">
        <Container>
          <Title tag="h2">Käyttäjäkokemussuunnittelu (UX Design)</Title>
          <Text>
            Käyttäjäkokemussuunnittelulla usein viitataan lyhenteeseen UX (User
            Experience Design). UX-suunnittelulla keskitytään siihen, miten
            käyttökokemusta voidaan kehittää ja parantaa.
          </Text>
          <Text>
            Kun puhutaan käyttökokemuksen suunnittelusta on tärkeää pyrkiä
            vastaamaan asiakkaan tarpeeseen. Tuotteiden käytön tulee olla
            asiakkaalle mieluisaa, joten suunnittelussa on osattava huomioida
            niin tyyli kuin yksinkertaisuus.
          </Text>
          <Text>
            Yksinkertaisesti käyttäjäkokemussuunnittelussa luodaan halutunlainen
            kokemus, esimerkiksi helppo, selkeä ja innostava käyttää. Samalla
            suunnittelussa pyritään tarjoamaan asiakkaalle paljon enemmän kuin
            he tietoisesti haluavat.
          </Text>
          <Text>
            Jotta saavutettaisiin korkealaatuinen käyttökokemus, on yrityksen
            palveluiden, tekniikan, markkinoinnin, graafisen- ja teollisen
            suunnittelun, sekä rajapintasuunnittelun oltava liitoksissa
            toisiinsa ja toimittava.
          </Text>
        </Container>
      </section>

      <section id="steps">
        <Container>
          <Title tag="h2">
            Käyttöliittymäsuunnittelun vaiheita ja periaatteita
          </Title>
          <div className="relative lg:grid md:grid-cols-2 md:gap-x-8">
            <div className="mt-10 md:mr-5">
              <Title tag="h3">Vaihe 1: Käyttöliittymän suunnittelu</Title>
              <Text>
                Aluksi käyttöliittymän suunnittelussa on osattava vastata
                esimerkiksi seuraavanlaisiin kysymyksiin:
              </Text>
              <BasicList>
                <li>Kenelle palvelu on tarkoitettu?</li>
                <li>Miksi palvelua käytetään?</li>
                <li>
                  Mille alustalle tai kielelle palvelu on tarkoitettu tehdä?
                </li>
                <li>Tarvitseeko käyttäjien kirjautua palveluun?</li>
                <li>Luovatko käyttäjät profiilin?</li>
                <li>Miten palvelu tuottaa rahaa?</li>
                <li>Antavatko käyttäjät palvelusta arvosteluja?</li>
                <li>Tarvitseeko palvelu yhteyden palvelimeen?</li>
                <li>Miltä palvelu tulisi näyttää?</li>
              </BasicList>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
              <StaticImage
                src="../images/3-right-trans-color.svg"
                alt="Mobiilikehitys elementtejä"
              />
            </div>
            <div className="mt-10">
              <Title tag="h3">Vaihe 2: Yksinkertainen suunnitelma</Title>
              <Text>
                Kun edellisiin kysymyksiin on vastattu, on aika lähteä
                piirtämään luonnosta siitä, miltä palvelu tulisi näyttämään.
                Piirroksen ei tarvitse olla hiottu eikä loppuun asti suunniteltu
                vaan pelkät ääriviivat riittävät. Tämän yksinkertaisen
                suunnitelman avulla näemme miten palvelun rakenne muodostuu ja
                pystymme miettimään sen toiminnallisuutta ja kehittää sitä ennen
                kuin muutoksia / lisäyksiä aletaan tekemään itse koodiin. Tämän
                tekeminen voi tuntua turhalta, mutta se tulee säästämään aikaa
                ja rahaa.
              </Text>
            </div>
            <div className="mt-10">
              <Title tag="h3">Vaihe 3: Käyttöliittymän testaaminen</Title>
              <Text>
                Kun ensimmäinen versio palvelusta on saatu valmistettua
                (ensimmäinen versio on harvoin paras mahdollinen), on aika
                testata palvelua. Palvelun jatkuva kehittäminen on
                välttämätöntä, jotta siitä saadaan kaikki sen sisältämä
                potentiaali irti. Parhaimman tuloksen tulet saamaan silloin, kun
                oikeat käyttäjät voivat antaa palautetta palvelusta, jonka
                johdosta tuotetta pystytään kehittämään tarkemmin
                käyttäjäystävällisemmäksi.
              </Text>
            </div>
            <StaticImage
              src="../images/4-left-trans-color.svg"
              alt="Ympyrädiagrammeja viivakaavion päällä"
            />
            <div className="mt-10 md:mr-5">
              <Title tag="h3">Periaatteet</Title>
              <div className="mt-5">
                <SubTitle tag="h4">Poista turha</SubTitle>
                <Text>
                  Kaikki mikä ei tuota asiakkaalle lisäarvoa on turhaa.
                  Esimerkiksi prototyyppivaiheessa tehty pikselintarkka
                  käyttöliittymä on turhaa rahan ja ajan haaskaamista.
                </Text>
              </div>
              <div className="mt-5">
                <SubTitle tag="h4">Palvelun kehittäminen</SubTitle>
                <Text>
                  On tärkeää kehittää palvelua ja keksiä uusia ratkaisuja.
                  Kokeilemalla ja oppimalla tulee aina parempi lopputulos, kuin
                  jämähtämällä ensimmäiseen OK-versioon.
                </Text>
              </div>
              <div className="mt-5">
                <SubTitle tag="h4">Palvelun toimittaminen käyttäjille</SubTitle>
                <Text>
                  Heti kun on vain mahdollista, niin palvelu kannattaa laittaa
                  käyttäjien saataville. Näin saat nopeasti palautetta
                  tuotteesta/palvelusta ja pystyt kehittämään palvelua entistä
                  paremmin tietämällä tarkalleen sen, mitä käyttäjät haluavat
                  kyseiseltä palvelulta.
                </Text>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section id="special-features" className="bg-gray-800">
        <Container>
          <div>
            <Title tag="h2" textColor="text-white">
              Erityispiirteitä ja huomioita
            </Title>
            <Text textColor="text-white">
              Käyttöliittymäsuunnittelu ja käyttäjäkokemussuunnittelu ovat
              muuttuvia kenttiä. Jatkuvasti muuttuvat ja erilaiset trendit
              pitävät huolen siitä, että kehityksessä mukana pysyminen on
              välttämätöntä. Jos tuotteesi tai palvelusi on niin sanotusti ‘last
              season’, olet kilpailun alimmaisena. Käyttäjät ovat raakoja sen
              suhteen, mitä he tänä päivänä haluavat.
            </Text>
            <Text textColor="text-white">
              Esimerkiksi suunniteltaessa verkkosivuja, -kauppoja ja sovelluksia
              on mietittävä miten sovellus on helposti saavutettavissa ja
              esteetön. On huomioitava responsiivisuus,
              selain/laiteyhteensopivuus sekä riittävä latausnopeus käyttäjän
              hitaallakin verkkoyhteydellä. Ohjelmistot on myös osattava
              suunnitella heikkonäköisille ja iäkkäämmille käyttäjille
              sopivaksi.
            </Text>
          </div>
          <div className="mt-10">
            <Title tag="h3" textColor="text-white">
              Käyttäjälähtöinen vai käyttäjää ohjaava suunnittelu
            </Title>
            <Text textColor="text-white">
              Käyttöliittymäsuunnittelu ja käyttäjäkokemussuunnittelu aloitetaan
              usein joko käyttäjälähtöisestä tai käyttäjää ohjaavasta
              suunnittelusta ja lähestymällä niitä siitä kannalta.
            </Text>
            <div className="grid lg:grid-cols-2 mt-10">
              <div className="lg:mr-5">
                <Title tag="h4" textColor="text-white">
                  Käyttäjälähtöinen suunnittelu
                </Title>
                <Text textColor="text-white">
                  Käyttäjälähtöisen suunnittelun perustana on käyttäjien tarpeet
                  ja tottumukset. Suunnittelussa hyödynnetään asiakkaiden
                  antamaa palautetta, analytiikkaa sekä tietoja siitä, miten
                  käyttäjät ovat toimineet aikaisemmin. Käyttäjälähtöisen
                  suunnittelun tavoitteena on siis luoda käyttöliittymä ja
                  käyttökokemus, mikä mukailee käyttäjille luonnollista tapaa
                  toimia.
                </Text>
              </div>
              <div>
                <Title tag="h4" textColor="text-white">
                  Käyttäjää ohjaava suunnittelu
                </Title>
                <Text textColor="text-white">
                  Käyttäjää ohjaavassa suunnittelussa korostuu, miten käyttäjää
                  voidaan ohjata toimimaan halutulla tavalla. Esimerkiksi
                  tavoitteena voi olla opettaa käyttäjille uusia toimintatapoja.
                  Tätä voidaan hyödyntää silloin, kun laite tai järjestelmä on
                  käyttäjälle yleisesti tuttu, mutta käyttäjille yritetään
                  opettaa uusia tapoja käyttää laitetta tai järjestelmää.
                </Text>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
